import React from 'react'
import Desc from '../../components/desc/desc'
import styled from 'styled-components'
import { object } from 'prop-types'

const Wrap = styled.article`
  /* display: flex; */
  display: grid;
  row-gap: 10px;
  width: 100%;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: 20px 40px;
  /* border: 2px solid ${({ theme }) => theme.colorGrey}; */
  /* border: 2px solid #e5e5e5; */
  /* border-top: 4px solid ${({ theme }) => theme.colorYellow}; */
  border-radius: 10px;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    /* top: 0; */
    left: 17px;
    width: 4px;
    height: 70%;
    background-color: ${({ theme }) => theme.colorYellow};
  }
`

const CardNews = props => {
  return (
    <Wrap>
      <Desc align="left" color="colorBlack" as="h3" bold>
        {props.obj.date}
      </Desc>
      <Desc align="left" 
    //   color="colorBlack"
      >
        <div
          dangerouslySetInnerHTML={{ __html: props.obj.desc }}
          className="cms-text"
        />
      </Desc>
    </Wrap>
  )
}

export default CardNews
