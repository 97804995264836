import styled from 'styled-components'
import React from 'react'

const Sign = styled.span`
  color: ${({ theme }) => theme.colorYellow};
  font-weight: 500;
  margin-right: 5px;
`

const props = () => {
  return <Sign>{'>'}</Sign>
}

export default props
