import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'
import Desc from '../../../components/desc/desc'
import Sign from '../../../components/sign/sign'
import MiniLink from '../../../components/link/link'
import ContentWrap from '../../../components/contentWrap/contentWrap'
import { Carousel } from 'react-bootstrap'
import SectionHeader from '../../../components/sectionHeader/sectionHeader'
import 'bootstrap/dist/css/bootstrap.min.css'

const TextWrap = styled(ContentWrap)`
  position: absolute;
  top: 15%;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  z-index: 130;
  margin: 0;
  display: grid;
  row-gap: 20px;

  @media (min-width: 768px) {
    transform: translateY(-50%);
    top: 50%;
    left: 15%;
  }
  @media (min-width: 960px) {
    left: 20%;
  }
  @media (min-width: 1200px) {
    row-gap: 40px;
  }
  @media (min-width: 1400px) {
    left: 13%;
  }
  @media (min-width: 1600px) {
    left: 16%;
  }
  @media (min-width: 1800px) {
    left: 22%;
  }
`

const LinksList = styled.ul`
  top: 60%;
`

const LinkWrapper = styled.li``

const CarouselWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: grid;
  justify-content: center;
  @media (max-width: 768px) and (orientation: landscape) {
    min-height: 150vh;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
  }
`

const LandingImg = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;

  @media (max-width: 768px) and (orientation: landscape) {
    min-height: 150vh;
  }
`

const StyledSectionHeader = styled(SectionHeader)`
  &::first-letter {
    color: inherit;
  }
  font-size: 40px;
  z-index: 100;

  @media (min-width: 768px) {
    font-size: 56px;
  }
  @media (min-width: 960px) {
    font-size: 64px;
  }
  @media (min-width: 1400px) {
    font-size: 70px;
  }
`
const StyledDesc = styled(Desc)`
  left: 10%;
  top: 35%;
  z-index: 100;
  max-width: 600px;
`

const LandingPage = props => {
  return (
    <CarouselWrap>
      <TextWrap>
        <StyledSectionHeader align="left">
          <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.data.baner}
          />
        </StyledSectionHeader>

        <StyledDesc color="colorWhite" align="left">
          {/* <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.data.banerDesc}
          /> */}
          <div
            dangerouslySetInnerHTML={{ __html: props.data.banerDesc }}
            className="cms-text"
          />
        </StyledDesc>

        <LinksList>
          <LinkWrapper>
            <MiniLink color="colorWhite" to="/kursy">
              <Desc color="colorWhite" align="left">
                <Sign />
                {/* zobacz ofertę kursów */}
                <ReactMarkdown
                  disallowedTypes={['paragraph']}
                  unwrapDisallowed
                  children={props.data.linkCourse}
                />
              </Desc>
            </MiniLink>
          </LinkWrapper>
          <LinkWrapper>
            <MiniLink color="colorWhite" to="/kontakt">
              <Desc color="colorWhite" align="left">
                <Sign />
                {/* skontaktuj się */}
                <ReactMarkdown
                  disallowedTypes={['paragraph']}
                  unwrapDisallowed
                  children={props.data.linkContact}
                />
              </Desc>
            </MiniLink>
          </LinkWrapper>
        </LinksList>
      </TextWrap>

      {/* <Carousel controls={false} indicators={false} interval={2500}>
        <Carousel.Item>
          <LandingImg
            src={props.data.homeLandingImages[0].url}
            alt="Zdjęcie samochodu"
          />
        </Carousel.Item>

        <Carousel.Item>
          <LandingImg
            src={props.data.homeLandingImages[1].url}
            alt="Zdjęcie samochodu"
          />
        </Carousel.Item>
      </Carousel> */}

      <Carousel controls={false} indicators={false} interval={2500}>
        {props.data.homeLandingImages.map(image => (
          <Carousel.Item key={image.url}>
            <LandingImg src={image.url} alt="Zdjęcie samochodu" />
          </Carousel.Item>
        ))}
      </Carousel>
    </CarouselWrap>
  )
}

export default LandingPage
