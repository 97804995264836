import React, { useState, useEffect } from 'react'
import LandingSection from '../sections/index/landingSection/landingSection'
import NewsSection from '../sections/index/newsSection/newsSection'
import MidSection from '../sections/index/midSection/midSection'
import OpinionsSection from '../sections/index/opinionsSection/opinionsSection'
import Layout from '../layout/layout'
import Loader from '../partials/loader/loader'

import axios from '../utils/axios'
import contactQuery from '../utils/contactQuery'
import Footer from '../partials/footer/footer'

import Modal from '../components/modal/modal'

const Home = props => {
  const [respData, setRespData] = useState({})
  const [isDataFetched, setIsDataFetched] = useState(false)
  const [isModalOn, setIsModalOn] = useState(false)

  useEffect(() => {
    const fc = async () => {
      const response = await axios({
        data: JSON.stringify({
          query: `{
          homePage{
            baner
            banerDesc
            homeLandingImages {
              url
            }
            linkCourse
            linkContact
            headWhyus1
            whyus1
            headWhyus2
            whyus2
            headWhyus3
            whyus3
            courseA
            courseB
            imgCourseA{
              url
            }
            imgCourseB{
              url
            }
            imgOpinions{
              url
            }
          }
          allHomepageOpinions{
            author
            content
          }
          modal{
            title
            desc
            btn1
            btn2
          }
          allNewsinfos{
            date
            desc
            number
          }
          ${contactQuery}
        }`,
        }),
      })
      const why1 = response.data.data.homePage.whyus1
      const why2 = response.data.data.homePage.whyus2
      const why3 = response.data.data.homePage.whyus3
      response.data.data.homePage.whyus = [why1, why2, why3]
      setRespData(response.data.data)
      setIsDataFetched(true)
    }
    fc()
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => setIsModalOn(true), 10 * 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      {<Loader isDataLoaded={isDataFetched} />}
      {isDataFetched && (
        <Layout title="Strona Główna">
          <Modal
            isOpen={isModalOn}
            close={() => setIsModalOn(false)}
            data={respData.modal}
          />
          <LandingSection data={respData.homePage} />
          <NewsSection data={respData.allNewsinfos} />
          <MidSection data={respData.homePage} />
          <OpinionsSection
            data={respData.allHomepageOpinions}
            ulr={respData.homePage.imgOpinions.url}
          />
          <Footer data={respData.contact} />
        </Layout>
      )}
    </>
  )
}

export default Home
