import React from 'react'
import styled from 'styled-components'
import Header from '../header/header'
import Desc from '../desc/desc'
import Button from '../button/button'
import Link from '../link/link'

const Wrap = styled.div`
  display: grid;
  /* row-gap: 10px; */
  padding: 20px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: ${props =>
    props.isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, -200vw)'};
  transition: 0.3s;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colorWhite};
  box-shadow: 10px 11px 44px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media (min-width: 960px) {
    padding: 20px 40px;
  }
`

const StyledHeader = styled(Header)`
  margin: 0;
  & * {
    margin: 0;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 999;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: 0.3s;
  pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
`

const ButtonWrap = styled.div`
  display: grid;
  row-gap: 15px;
  /* margin-top: 10px; */
`

const StyledButton = styled(Button)`
  padding: 8px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GreyButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colorGrey};
`

const Modal = props => {
  return (
    <>
      <Wrap isOpen={props.isOpen}>
        <StyledHeader>
          <span
            dangerouslySetInnerHTML={{ __html: props.data.title }}
            className="cms-text"
          />
        </StyledHeader>

        <Desc>
          <span
            dangerouslySetInnerHTML={{ __html: props.data.desc }}
            className="cms-text"
          />
        </Desc>
        <ButtonWrap>
          <StyledButton as="span">
            <Link to="kontakt">
              <span
                dangerouslySetInnerHTML={{ __html: props.data.btn1 }}
                className="cms-text"
              />
            </Link>
          </StyledButton>
          <GreyButton onClick={() => props.close()}>
            <span
              dangerouslySetInnerHTML={{ __html: props.data.btn2 }}
              className="cms-text"
            />
          </GreyButton>
        </ButtonWrap>
      </Wrap>
      <Background isOpen={props.isOpen} />
    </>
  )
}

export default Modal
