import React from 'react'
import styled from 'styled-components'

import ContentWrap from '../../../components/contentWrap/contentWrap'
import Header from '../../../components/header/header'

import NewsCard from '../../../components/cardNews/cardNews'

// const data = ['', '']

const StyledContentWrap = styled(ContentWrap)`
  margin-bottom: 60px;
`

const NewsSection = props => {
  const data = [...props.data]
  data.sort((a, b) => a.number > b.number)
  return (
    <StyledContentWrap>
      <Header h2 firsLletter>
        Aktualności
      </Header>
      {data.map(obj => (
        <NewsCard obj={obj} />
      ))}
    </StyledContentWrap>
  )
}

export default NewsSection
