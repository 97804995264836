import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Header from '../header/header'
import Desc from '../desc/desc'
import Line from '../line/line'

import Fade from 'react-reveal/Fade'

const StyledDesc = styled(Desc)`
  width: 80%;
  @media (min-width: 768px) {
    text-align: center;
  }
`

const StyledLine = styled(Line)`
  width: 30%;
  height: 3px;
  /* background-color: transparent; */
`

const Wrap = styled.article`
  display: grid;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  max-width: 360px;
`

const Img = styled.img`
  width: 50%;
  max-width: 90px;
  margin-bottom: 20px;
`

const Card = props => {
  return (
    <Fade>
      <Wrap>
        <Img src={props.img} alt="Ikonka" />
        <Header leftLine yellowRect as="h3">
          <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.title}
          />
        </Header>
        <StyledLine />
        <StyledDesc align="left">
          {/* <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.desc}
          /> */}
          <div
            dangerouslySetInnerHTML={{ __html: props.desc }}
            className="cms-text"
          />
        </StyledDesc>
      </Wrap>
    </Fade>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
}

export default Card
