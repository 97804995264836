import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'
import Desc from '../../components/desc/desc'
import Header from '../../components/header/header'
import MiniLink from '../../components/link/link'
import Fade from 'react-reveal/Fade'
import Sign from '../../components/sign/sign'

const Img = styled.img`
  width: 300px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
`

const YellowBox = styled.div`
  clip-path: polygon(13% 1%, 100% 0%, 88% 100%, 0% 100%);
  transform: translateY(-50%);
  /* background-color: ${({ theme }) => theme.colorYellow}; */
  background-color: ${({ theme }) => theme.colorBlack};
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 6px 0;
  width: 110px;
`

const StyledDesc = styled(Desc)`
  margin: 10px 0 10px;
  width: 80%;
`

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  max-width: 350px;
`

const CardCourse = props => {
  return (
    <Fade>
      <Wrapper>
        <Img src={props.obj.img} />
        <YellowBox>
          <Header as={'h3'} color="colorYellow">
            <ReactMarkdown
              disallowedTypes={['paragraph']}
              unwrapDisallowed
              children={props.obj.title}
            />
          </Header>
        </YellowBox>
        <StyledDesc>
          {/* <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.obj.desc}
          /> */}
          <div
            dangerouslySetInnerHTML={{ __html: props.obj.desc }}
            className="cms-text"
          />
        </StyledDesc>
        <MiniLink color="colorBlack" to="/kursy">
          <Desc bold color="colorBlack">
            <Sign />
            Czytaj więcej...
          </Desc>
        </MiniLink>
      </Wrapper>
    </Fade>
  )
}

export default CardCourse
