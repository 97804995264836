import React from 'react'

import Header from '../../../components/header/header'

import styled from 'styled-components'

import { Carousel } from 'react-bootstrap'
import CardOpinion from '../../../components/cardOpinion/cardOpinion'
import bg from '../../../images/home/opinions.jpg'

const Wrap = styled.section`
  width: 100%;
  padding: 40px 0;
  position: relative;
  background-image: url(${props => props.url || bg});
  background-position: center;
  background-size: cover;
`

const CarouselWrap = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
`

const StyledHeader = styled(Header)`
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    margin-bottom: 60px;
  }
`
// const Li = styled.li`
//   width: 100%;
// `

const Opinions = props => {
  return (
    <>
      <StyledHeader h2 firstLetter>
        OPINIE KURSANTÓW
      </StyledHeader>
      <Wrap url={props.url}>
        <CarouselWrap>
          <Carousel indicators={false} interval={2500}>
            {props.data.map(el => (
              <Carousel.Item key={el.author}>
                {/* <Li key={el.author}> */}
                <CardOpinion text={el.content} author={el.author} />
                {/* </Li> */}
              </Carousel.Item>
            ))}
          </Carousel>
        </CarouselWrap>
      </Wrap>
    </>
  )
}

export default Opinions
