import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'
import Desc from '../../components/desc/desc'
import Header from '../../components/header/header'

import quote from '../../images/home/quote.svg'

const Wrap = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 350px;
  padding: 0 40px;
  @media (min-width: 768px) {
    /* padding: 0 100px; */
    padding: 0 15vw;
  }
  @media (min-width: 960px) {
    height: 300px;
  }
  @media (min-width: 1200px) {
    /* padding: 0 100px; */
    padding: 0 25vw;
  }

  /* max-width: 600px; */
`
const Img = styled.img`
  margin-right: ${props => (props.left ? 'auto' : null)};
  margin-left: ${props => (props.right ? 'auto' : null)};
  transform-origin: 50% 50%;
  transform: ${props =>
    props.right ? 'rotate(180deg) translateY(100%)' : null};
`

const StyledHeader = styled(Header)`
  text-transform: capitalize;
`

const CardOpinion = props => {
  return (
    <Wrap>
      <Img left src={quote} />
      <Desc color="colorWhite">{props.text}</Desc>
      <Img right src={quote} />
      <StyledHeader as={'h4'} author color="colorYellow">
        <ReactMarkdown
          disallowedTypes={['paragraph']}
          unwrapDisallowed
          children={props.author}
        />
      </StyledHeader>
    </Wrap>
  )
}

export default CardOpinion
