import React from 'react'

import styled from 'styled-components'

import Header from '../../../components/header/header'

import ContentWrap from '../../../components/contentWrap/contentWrap'
import CardUs from '../../../components/cardUs/cardUs'
import CardCourse from '../../../components/cardCourse/cardCourse'

import usCar from '../../../images/home/car.svg'
import usCalendar from '../../../images/home/calendar.svg'
import usBrain from '../../../images/home/brain.svg'

const List = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: space-evenly;
  margin: 0 auto;
  width: 100%;
`

const StyledHeader = styled(Header)`
  /* margin-bottom: 25px; */
`

const StyledContentWrap = styled(ContentWrap)`
  @media (min-width: 1400px) {
    max-width: 1200px;
    margin-bottom: 100px;
  }
`

const MidPage = props => {
  const dataUs = [
    {
      title: props.data.headWhyus1,
      img: usCalendar,
      text: props.data.whyus1,
    },
    {
      title: props.data.headWhyus2,
      img: usBrain,
      text: props.data.whyus2,
    },
    {
      title: props.data.headWhyus3,
      img: usCar,
      text: props.data.whyus3,
    },
  ]

  const dataCourse = [
    {
      title: 'A/A2',
      desc: props.data.courseA,
      img: props.data.imgCourseA.url,
    },
    {
      title: 'B',
      desc: props.data.courseB,
      img: props.data.imgCourseB.url,
    },
  ]

  return (
    <>
      <StyledContentWrap>
        <StyledHeader h2 firstLetter>
          Dlaczego my?
        </StyledHeader>
        <List>
          {dataUs.map(el => (
            <li key={el.title}>
              <CardUs title={el.title} desc={el.text} img={el.img} />
            </li>
          ))}
        </List>
      </StyledContentWrap>

      <ContentWrap as="main">
        <StyledHeader h2 firstLetter>
          Kategorie kursów
        </StyledHeader>
        <List>
          {dataCourse.map(el => (
            <li key={el.title}>
              <CardCourse obj={el} />
            </li>
          ))}
        </List>
      </ContentWrap>
    </>
  )
}

export default MidPage
